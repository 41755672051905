<template>
  <b-container fluid class="bg-white">
    <b-row>
      <img src="@/assets/icon.png" class="w-75 mx-auto my-3" alt="" />
    </b-row>
    <b-row>
      <div class="mx-5 px-3 w-100" style="text-align: justify">
        <h4 v-html="$t('TermTitle1')"></h4>
        <p v-html="$t('TermsDesc1')"></p>
        <br />
        <h4 v-html="$t('TermTitle2')"></h4>
        <p v-html="$t('TermDesc2')"></p>
        <br />
        <h4 v-html="$t('TermTitle3')"></h4>
        <p v-html="$t('TermDesc3')"></p>
        <br />
        <!-- <h4>{{$t("TermTitle3")}}</h4>
              <p>{{$t("TermDesc2")}}</p>
              <br> -->
        <h4 v-html="$t('TermTitle4')"></h4>
        <p v-html="$t('TermDesc4')"></p>
        <br />
        <h4 v-html="$t('TermTitle5')"></h4>
        <p v-html="$t('TermDesc5')"></p>
        <br />
        <!-- <h4 v-html='$t("TermTitle6")'></h4>
              <p v-html='$t("TermDesc6")'></p>
              <br> -->
        <h4 v-html="$t('TermTitle7')"></h4>
        <p v-html="$t('TermDesc7')"></p>
        <br />
        <h4 v-html="$t('TermTitle8')"></h4>
        <p v-html="$t('TermDesc8')"></p>
        <br />
        <h4 v-html="$t('TermTitle9')"></h4>
        <p v-html="$t('TermDesc9')"></p>
        <br />
        <h4 v-html="$t('TermTitle10')"></h4>
        <p v-html="$t('TermDesc10')"></p>
        <br />
        <h4 v-html="$t('TermTitle11')"></h4>
        <p v-html="$t('TermDesc11')"></p>
        <br />
        <h4 v-html="$t('TermTitle12')"></h4>
        <p v-html="$t('TermDesc12')"></p>
        <br />
        <h4 v-html="$t('TermTitle13')"></h4>
        <p v-html="$t('TermDesc13')"></p>
        <br />
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  metaInfo: {
    title: "Terms",
             meta: [
      {
        vmid: "description",
        name: "description",
        content:"تحدد هذه الشروط والأحكام ضوابط استخدام موقع ) YAMHAD (، حيث يعد استخدام هذا الموقع قبولً تاماً بكامل هذه الشروط والأحكام، فإن لم يكن المستخدم للموقع موافقاً عليها أو على أي جزء منها فيجب عليه المتناع عن استخدام موقعنا اللكتروني."      },
    ],
  },
  mounted() {
    this.$gtag.event("Terms Page", { method: "Google" });
  },
};
</script>

<style>
</style>